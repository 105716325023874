<div class="customer-main-details">
  <div class="customer-main-details-wrapper">
    <div class="customer-main-details-left">
      <div>
        <div class="customer-main-details-tvd">
          {{ "TVD.TOTALVALUE" | translate }}
        </div>
        <div class="customer-main-details-tvd-wrapper">
          <div class="customer-main-details-Million">
            <span>
              <span class="customer-main-details-data">{{
                totalValue
                  | numberSuffix: transformationType.withCurrency : currencyCode
              }}</span>
            </span>
          </div>
          <div>
            <div class="customer-main-details-usd">{{ currencyCode }}</div>
          </div>
        </div>
        <div class="customer-align">
          <div class="customer-visibility">
            <div class="ellipse ellipse-tvd"></div>
            <div class="customer-data-label">
              {{ "TVD.TOTALVALUEDELIVERED" | translate }}
            </div>
          </div>
          <div class="customer-visibility">
            <div class="ellipse ellipse-opportunity"></div>
            <div class="customer-data-label">
              {{ "TVD.TOTALVALUEPIPELINE" | translate }}
            </div>
          </div>
          <div class="customer-visibility" *ngIf="isBicEnabled">
            <div class="ellipse ellipse-bic"></div>
            <div class="customer-data-label">{{ "TVD.BIC" | translate }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="customer-main-details-right">
      <div class="customer-value customer-value-align">
        <span
          [ngClass]="
            totalValueDeliveredInMillion || totalValueDeliveredInMillion == 0
              ? 'customer-value-wrapper'
              : 'customer-value-no-wrap'
          "
        >
          <span>{{
            totalValueDeliveredInMillion
              | numberSuffix: transformationType.withCurrency : currencyCode
          }}</span>
          <span class="customer-value-usd">{{ currencyCode }}</span>
          <span
            class="customer-value-details"
            (click)="onViewDetails('programDetails')"
            >{{ "TVD.VIEWDETAILS" | translate }}</span
          >
        </span>
        <div id="tvd"></div>
      </div>
      <div class="customer-value">
        <div>
          <div
            [ngClass]="
              totalOpportunityInMillion || totalOpportunityInMillion == 0
                ? 'customer-value-wrapper'
                : 'customer-value-no-wrap'
            "
          >
            <span>{{
              totalOpportunityInMillion
                | numberSuffix: transformationType.withCurrency : currencyCode
            }}</span>
            <span class="customer-value-usd">{{ currencyCode }}</span>
            <span
              class="customer-value-details"
              (click)="onViewDetails(programDetailsDeepLink)"
              >{{ "TVD.VIEWDETAILS" | translate }}</span
            >
          </div>
          <div id="enterpriseSitePipleine"></div>
        </div>
      </div>
      <div class="customer-value" [hidden]="!isBicEnabled">
        <div>
          <div
            [ngClass]="
              BIC_PotentialInMillion || BIC_PotentialInMillion === 0
                ? 'customer-value-wrapper'
                : 'customer-value-no-wrap'
            "
          >
            <span>{{
              BIC_PotentialInMillion
                | numberSuffix: transformationType.withCurrency : currencyCode
            }}</span>
            <span class="customer-value-usd">{{ currencyCode }}</span>
            <span
              class="customer-value-details"
              (click)="onViewDetails(bicDetailsDeepLink)"
            >
              {{ "TVD.VIEWDETAILS" | translate }}
            </span>
          </div>
          <div id="totalBic"></div>
        </div>
      </div>
    </div>
  </div>
</div>
