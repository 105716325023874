<mat-sidenav-container class="sidenav-container" [hasBackdrop]="true">
  <mat-sidenav
    #sidenav
    mode="over"
    position="end"
    class="sidenav-panel"
    [fixedInViewport]="true"
    [fixedTopGap]="0"
    [fixedBottomGap]="0"
  >
    <mat-toolbar color="primary">
      <div class="all-filter-label-top">
        <div class="all-filter-label-top-text">
          {{ "TVD.FILTER_RESULTS" | translate }}
        </div>
        <div class="all-filter-label-top-close">
          <div
            class="all-filter-label-top-close-text"
            (click)="openAllFilterView()"
          >
            {{ "TVD.CLOSE" | translate }}
          </div>
          <div
            class="all-filter-label-top-close-icon"
            (click)="openAllFilterView()"
          ></div>
        </div>
      </div>
      <div class="all-filter-no-of-filters">
        {{ selectedFilterCount }} {{ "TVD.NUMBER_OF_FILTERS" | translate }}
      </div>
    </mat-toolbar>
    <mat-card>
      <mat-card-content>
        <div class="all-filter-view-by-wrapper">
          <div class="all-filter-view-by-wrapper-title">
            {{ "TVD.VIEW_BY" | translate }}
          </div>
        </div>
        <mat-button-toggle-group
          name="fontStyle"
          aria-label="Font Style"
          [value]="selectedFilterType"
          class="full-width"
        >
          <mat-button-toggle
            [value]="filterType.CVOC"
            id="customerID"
            name="CUSTOMER_VIEW"
            (click)="changeToggle(filterType.CVOC)"
            >{{ "TVD.CUSTOMER_VIEW" | translate }}</mat-button-toggle
          >
          <mat-button-toggle
            [value]="filterType.EVOC"
            id="ecolabID"
            name="ECOLAB_VIEW"
            (click)="changeToggle(filterType.EVOC)"
            >{{ "TVD.ECOLAB_VIEW" | translate }}</mat-button-toggle
          >
          <mat-button-toggle
            [value]="filterType.SITES"
            id="sitesID"
            name="SITES_VIEW"
            (click)="changeToggle(filterType.SITES)"
            >{{ "TVD.SITES" | translate }}</mat-button-toggle
          > </mat-button-toggle-group
        ><br />
        <br />
        @if (displayAllFilter) {
          @if (selectedFilterType === filterType.CVOC) {
            <app-cvoc-filter
              (enableApply)="checkToEnableApply($event)"
            ></app-cvoc-filter>
          }
          @if (selectedFilterType === filterType.EVOC) {
            <app-evoc-filter
              (enableApply)="checkToEnableApply($event)"
            ></app-evoc-filter>
          }
          @if (selectedFilterType === filterType.SITES) {
            <app-site-filter
              [siteFilter]="siteFilter"
              (enableApply)="checkToEnableApply($event)"
            ></app-site-filter>
          }
          <app-operating-type-filter
            (enableApply)="checkToEnableApply($event)"
          ></app-operating-type-filter>
          <app-division-filter
            (enableApply)="checkToEnableApply($event)"
          ></app-division-filter>
        }
      </mat-card-content>
      <mat-toolbar class="footer-toolbar">
        <button
          type="button"
          id="cancelBtnTheme"
          mat-raised-button
          (click)="openAllFilterView()"
          style="color: #006bd3; border-color: #006bd3"
        >
          {{ "TVD.CANCEL" | translate }}
        </button>
        <button
          type="button"
          class="next-btn"
          mat-raised-button
          color="primary"
          (click)="calculateTotalFilters()"
          [disabled]="disableApply"
        >
          {{ "TVD.APPLY" | translate }}
        </button>
      </mat-toolbar>
    </mat-card>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">
    @if (isBusy) {
      <mat-spinner [diameter]="50" class="allfilters-spinner"></mat-spinner>
    } @else {
      <button
        (click)="openAllFilterView()"
        mat-raised-button
        [ariaDisabled]="isBusy"
        extended
        class="filter-btn"
        color="primary"
      >
        <mat-icon>
          <img [src]="FILTER_ICON" alt="Filter Icon" />
        </mat-icon>
        <span class="filter">{{ "TVD.ALLFILETERS" | translate }}</span>
      </button>
    }
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
