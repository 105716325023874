import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  DEFAULT_EVOC_SELECTION,
  EVOC_FILTER_NAMES,
} from '../../constant/tvd-constant';
import { FormControl } from '@angular/forms';
import {
  IEVOC,
  IEVOCRequest,
  SelectedEVOC,
} from '../../interfaces/IEVOCHierarchy';
import { Observable, map, startWith } from 'rxjs';
import { ICustomerInfo } from '../../interfaces/customer.interface';
import { FilterService } from '../../services/filter.service';
import { CustomerNameErrorStateMatcher } from '../../classes/customerNameTextMatcher';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ScrollService } from './../../services/scroll.service';
import {
  DOWN_ARROW_ICON,
  ERROR_ICON,
} from '../../../../shared/constants/app.constants';

const REGION = 'region';
const MARKET = 'market';
const ALLOCATION_MARKET = 'allocationMarket';
const COUNTRY = 'country';
@Component({
  selector: 'app-evoc-filter',
  templateUrl: './evoc-filter.component.html',
  styleUrl: './evoc-filter.component.scss',
})
export class EVOCFilterComponent {
  @ViewChildren(MatAutocompleteTrigger)
  autoComplete!: QueryList<MatAutocompleteTrigger>;
  @Output() enableApply: EventEmitter<any> = new EventEmitter();
  matcher = new CustomerNameErrorStateMatcher();
  selectedFilterCount: number = 0;
  customerInfo: ICustomerInfo;
  evocfilterIterations: number[] = new Array(1);
  evocFilterNames: string[] = [...EVOC_FILTER_NAMES];
  evoc_L1_control = new FormControl('');
  evoc_L2_control = new FormControl('');
  evoc_L3_control = new FormControl('');
  evoc_L4_control = new FormControl('');
  evocInputControls: FormControl[] = [
    this.evoc_L1_control,
    this.evoc_L2_control,
    this.evoc_L3_control,
    this.evoc_L4_control,
  ];
  selectedEVOCFilter: SelectedEVOC[] = [...DEFAULT_EVOC_SELECTION];
  evocLevelResponse: IEVOC;
  filteredOptionsEvoc: Observable<string[]>;
  disableApply: boolean = true;
  errorIcon: string = ERROR_ICON;
  downArrowIcon: string = DOWN_ARROW_ICON;

  constructor(
    private _filterService: FilterService,
    private cdref: ChangeDetectorRef,
    private scrollService: ScrollService,
  ) {}

  ngOnInit() {
    window.addEventListener('scroll', this.onScroll.bind(this), true);
    let dataPresent: boolean = false;
    this._filterService.currentToggle.next('EVOC');
    this._filterService.selectedCVOC.next(null); //Making saved CVOC values as Null
    this._filterService.addedSites$.next(null); //Making saved sites as Null
    this._filterService.customerInfo.subscribe(
      (res) => (this.customerInfo = res),
    ); //making default call for 1st filter to reduce the lag time.
    const saved_evoc = this._filterService.appliedEVOC.value;
    if (saved_evoc?.length > 0) {
      this.fetchSavedEVOCValues(saved_evoc);
      dataPresent = true;
    }
    this.enableApply.emit({ apply: false, type: 'evoc', data: dataPresent });
  }

  onScroll(): void {
    this.scrollService.scrollEvent(null, this.autoComplete);
  }

  fetchSavedEVOCValues(evoc_data: SelectedEVOC[]) {
    if (evoc_data) {
      this.selectedEVOCFilter = [...evoc_data];
      if (evoc_data?.length == 0) {
        this.modifyEVOCFilterCount(evoc_data.length + 1);
        return;
      }
      this.modifyEVOCFilterCount(evoc_data.length);
      evoc_data.forEach((data, i) => {
        this.evocInputControls[i].setValue(data.selectedValue);
      });
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  displayFnEVOC(customerName: string) {
    return customerName;
  }

  /**
   * Retrieves and processes the EVOC options for the specified level.
   *
   * This method performs the following actions:
   * 1. Fetches the next EVOC filter based on the provided level.
   * 2. Modifies the EVOC filter count for the given level.
   * 3. Resets the filtered EVOC options.
   * 4. Persists the current EVOC data.
   *
   * @param {number} level - The level for which to retrieve and process EVOC options.
   */
  getCurrentLevelEVOCOptions(level: number) {
    this.fetchNextEVOCFilter(level - 1, this.selectedEVOCFilter);
    this.modifyEVOCFilterCount(level);
    this.filteredOptionsEvoc = null;
    this.persistEVOCData();
  }

  //Below method dynamically increases/decreases the dropdowns
  evocDynamicFilter(
    selectedValue: string,
    level: number,
    hierarchyName: string,
  ) {
    if (selectedValue && level < 5) {
      this.selectedEVOCFilter[level - 1] = {
        level,
        hierarchyName,
        selectedValue: selectedValue,
      };
      this.modifyEVOCFilterCount(level + 1);
      this.disableApply = false;
    } else {
      this.modifyEVOCFilterCount(level);
    }
  }

  validateEvocFilter(level: number) {
    let currentFilterList = this.GetEvocDataByLevel(level);
    let dataPresent: boolean;
    let validFilter = currentFilterList?.find((data) =>
      data
        ?.toLowerCase()
        .includes(this.evocInputControls[level - 1]?.value.toLowerCase()),
    );
    if (!this.evocInputControls[level - 1].value && level == 1) {
      this.disableApply = false;
      this.modifyEVOCFilterCount(level);
      dataPresent = false;
      this.selectedEVOCFilter = [];
      this.persistEVOCData();
    } else if (!this.evocInputControls[level - 1].value && level != 1) {
      this.disableApply = false;
      this.modifyEVOCFilterCount(level - 1);
      this.evocInputControls[level - 1].setErrors(null);
      dataPresent = true;
      this.persistEVOCData();
    } else if (
      currentFilterList?.find(
        (data) =>
          data?.toLowerCase() ===
          this.evocInputControls[level - 1]?.value.toLowerCase(),
      )
    ) {
      this.disableApply = false;
      this.evocInputControls[level - 1].setErrors(null);
      dataPresent = true;
    } else if (validFilter) {
      this.disableApply = true;
      this.evocInputControls[level - 1].setErrors(null);
      dataPresent = true;
    } else {
      this.disableApply = true;
      this.evocInputControls[level - 1].setErrors({ invalid: true });
      this.modifyEVOCFilterCount(level);
      dataPresent = true;
    }
    this.enableApply.emit({
      apply: this.disableApply,
      type: 'evoc',
      data: dataPresent,
    });
  }

  /**
   * Retrieves the evoc data based on the specified level.
   *
   * @param {number} level - The level for which to retrieve the evoc data.
   *                         Valid values are:
   *                         1 - Region
   *                         2 - Market
   *                         3 - Allocation Market
   *                         4 - Country
   * @returns {string[]} An array of strings containing the evoc data for the specified level.
   */
  GetEvocDataByLevel(level: number): string[] {
    let levelData: string[];
    switch (level) {
      case 1: {
        levelData = this.evocLevelResponse?.region;
        break;
      }
      case 2: {
        levelData = this.evocLevelResponse?.market;
        break;
      }
      case 3: {
        levelData = this.evocLevelResponse?.allocationMarket;
        break;
      }
      case 4: {
        levelData = this.evocLevelResponse?.country;
        break;
      }
    }
    return levelData;
  }

  modifyEVOCFilterCount(level: number) {
    if (level > 4) return;
    this.evocfilterIterations = new Array(level);
    this.evocInputControls.slice(level).forEach((control) => {
      control.setValue(null);
    });
    this.selectedEVOCFilter.fill(null, level, 5);
  }

  /**
   * Fetches the next level of EVOC filters based on the target level and selected filters.
   *
   * @param {number} [targetLevel=0] - The target level to fetch the EVOC filter for. Defaults to 0.
   * @param {SelectedEVOC[]} [selectedEVOCFilter] - An optional array of selected EVOC filters.
   *
   * @returns {void}
   */
  fetchNextEVOCFilter(
    targetLevel: number = 0,
    selectedEVOCFilter?: SelectedEVOC[],
  ) {
    const requestObj: IEVOCRequest = {
      customerName: this.customerInfo.customerName,
      startDate: this._filterService.startDate.value,
      endDate: this._filterService.endDate.value,
    };

    for (let i = 0; i <= targetLevel; i++) {
      switch (i) {
        case 1:
          requestObj.region = selectedEVOCFilter[0].selectedValue;
          break;
        case 2:
          requestObj.market = selectedEVOCFilter[1].selectedValue;
          break;
        case 3:
          requestObj.allocationMarket = selectedEVOCFilter[2].selectedValue;
          break;
      }
    }

    this._filterService
      .getNextEVOCFilter(requestObj, this.customerInfo.customerID)
      .subscribe((res) => {
        this.evocLevelResponse = res.response;
        this.filteredOptionsEvoc = this.evocInputControls[
          targetLevel
        ].valueChanges.pipe(
          startWith(''),
          map((value) => this._filter_evoc(value || '', targetLevel + 1)),
        );
      });
  }

  _filter_evoc(value: string, level: number): string[] {
    const filterValue = value.toLowerCase();
    let res: string[];
    let curEvocFilter = this.GetEvocDataByLevel(level);
    res = curEvocFilter.filter((val) =>
      val.toLowerCase().includes(filterValue),
    );
    return res;
  }

  ngOnDestroy() {
    this.persistEVOCData();
  }
  persistEVOCData() {
    if (this.selectedEVOCFilter) {
      this.selectedEVOCFilter = this.selectedEVOCFilter.filter(
        (x) => x?.selectedValue != null,
      );
      this._filterService.selectedEVOC.next(this.selectedEVOCFilter);
    }
  }

  /**
   * Handles the selection of an EVOC option based on the specified level.
   *
   * @param level - The level of the EVOC option selected (1 to 4).
   * @param isOptionSelected - Optional boolean indicating if the option is selected.
   *
   * This method performs the following actions:
   * - Retrieves the filter value based on the selected level and input control value.
   * - Calls the evocDynamicFilter method with the appropriate parameters.
   * - Emits an event to disable the apply button.
   * - Modifies the EVOC filter count if the conditions are not met.
   * - Resets the filtered options for EVOC.
   * - Persists the EVOC data.
   */
  onEvocOptionSelected(level: number) {
    let filterValue: string;
    if (this.evocInputControls[level - 1].value && level < 5) {
      switch (level) {
        case 1: {
          filterValue =
            this.evocLevelResponse.region?.find(
              (val) =>
                val.toLowerCase() ===
                this.evocInputControls[level - 1].value.toLowerCase(),
            ) || '';
          this.evocDynamicFilter(filterValue, level, REGION);
          break;
        }
        case 2: {
          filterValue =
            this.evocLevelResponse.market?.find(
              (val) =>
                val.toLowerCase() ===
                this.evocInputControls[level - 1].value.toLowerCase(),
            ) || '';
          this.evocDynamicFilter(filterValue, level, MARKET);
          break;
        }
        case 3: {
          filterValue =
            this.evocLevelResponse.allocationMarket?.find(
              (val) =>
                val.toLowerCase() ===
                this.evocInputControls[level - 1].value.toLowerCase(),
            ) || '';
          this.evocDynamicFilter(filterValue, level, ALLOCATION_MARKET);
          break;
        }
        case 4: {
          filterValue =
            this.evocLevelResponse.country?.find(
              (val) =>
                val.toLowerCase() ===
                this.evocInputControls[level - 1].value.toLowerCase(),
            ) || '';
          this.evocDynamicFilter(filterValue, level, COUNTRY);
          break;
        }
      }
      this.enableApply.emit({ apply: false, type: 'evoc', data: true });
    } else {
      this.modifyEVOCFilterCount(level);
    }
    this.filteredOptionsEvoc = null;
    this.persistEVOCData();
  }
}
