import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ITableConfiguration } from '../../interfaces/ITable';
import { IBICSiteDetail, IBICSiteResponse, IOfferingCalculatedValues } from '../../interfaces/IBIC';
import { BIC_LOCATION_MAPPER, BIC_MODAL, BIC_SITE, BIC_TABLE, generic_tableConfiguration } from '../../../../shared/constants/app.constants';
import { CustomerService } from '../../services/customer.service';
import { UserPreferenceService } from '../../services/user-preference.service';
import { FilterService } from '../../services/filter.service';
import { environment } from '../../../../../environments/environment';
import { ScrollService } from '../../services/scroll.service';
import { TableComponent } from '@reusable-lib/one-customer-ui-lib-table';

@Component({
  selector: 'app-bic-site-view',
  templateUrl: './bic-site-view.component.html',
  styleUrl: './bic-site-view.component.scss'
})
export class BicSiteViewComponent implements AfterViewInit{
  @ViewChild('bicDetails') targetSection: ElementRef;
  @ViewChild('tableComponent') tableComponent: TableComponent;
  @Input() rowInfo: IOfferingCalculatedValues;
  @Output() siteFlag: EventEmitter<any> = new EventEmitter();

  table_Configuration: ITableConfiguration = null;
  locationInfo: IBICSiteDetail;
  BIC_LITERALS = BIC_MODAL;
  uom: string;
  homeButtonURL: string = environment.DI_APP_LINK;
  currency: string;
  isLoading: boolean = false;
  BIC_SITE_LITERAL = BIC_SITE;

  constructor(private _customerService: CustomerService,
    private scrollService: ScrollService,
    private _userPreferenceService: UserPreferenceService,
    private _filterService: FilterService,
    private detect: ChangeDetectorRef) { }

  /**
   * Takes the control back to Landing Page
   */
  backToDashBoard() {
    this.siteFlag.emit(false);
    this.scrollService.scrollToSection(BIC_TABLE.TEMPLATE_REFERENCE);
  }

  ngOnInit() {
    this.generate_BIC_Configuration();
    this.fetchLocationInfo();
    this._customerService.resetTableOncustNameChange.subscribe((resetStatus)=>{
      if(resetStatus) {
        this.tableComponent.makeChanges();
        this.detect.detectChanges();
      }

    })
  }

  ngAfterViewInit(): void {
    this._customerService.isFilterApplied$.subscribe(
     isApplied => {
       if (isApplied) {
         this.tableComponent.makeChanges();
         this.tableComponent.resetSortState();
       }
     })
 }

  /**API call to fetch the list of Sites for a particular Offering*/
  fetchLocationInfo() {
    this.isLoading = true;
    let customerID = this._filterService.customerInfo.value.customerID;
    if (customerID) {
      this._userPreferenceService.getUserPreferencesObservable().subscribe(preferences => {
        if (preferences) {
          this.uom = preferences.uom;
          this.currency = preferences.currency;
        }
        const reqPayload = this._filterService.getAppliedOptionalFilters(this.uom, this.currency, true)
        const encodedOfferingName = btoa(this.rowInfo.offeringName);//converting string to base64
        this._customerService.fetchBICLocation<IBICSiteResponse>(customerID, reqPayload, encodedOfferingName).subscribe(
          res => {
            this.locationInfo = res;
            this.isLoading = false;
            this.sortBicTableData();
          },
          error => {
            this.isLoading = false;
          });
      });
  }
}

  generate_BIC_Configuration() {
    let columnList = BIC_LOCATION_MAPPER.map(item => item.fieldName);
    this.table_Configuration = {
      columnList: columnList,
      mapper: BIC_LOCATION_MAPPER,
      tableTitle: '',
      tableType: '',
      pageSizeOptions: generic_tableConfiguration.pageSizeOptions,
      pageSize: BIC_TABLE.DEFAULT_PAGESIZE,
      img: BIC_TABLE.ICON
    }
  }

  /**
  * Sorts the locationInfo.offeringSites array in descending order based on the 
  * ValuePotential property. Ensures the highest valuePotential appears first.
  */
  sortBicTableData() {
    if (this.locationInfo.offeringSites && Array.isArray(this.locationInfo.offeringSites)) {
      this.locationInfo.offeringSites.sort((firstItem, secondItem) =>
        secondItem.valuePotential - firstItem.valuePotential
      );
    }
  }
}
