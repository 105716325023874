import { Component, Input, SimpleChanges } from '@angular/core';
import { BIC_TABLE, LANDINGPAGE, PROGRAM_TABLE, TRANSFORMATION_TYPE } from '../../constants/app.constants';
import { ScrollService } from '../../../features/tvd-setup/services/scroll.service';
import { IBICInfo } from '../../../features/tvd-setup/interfaces/IBIC';
import { CustomerService } from '../../../features/tvd-setup/services/customer.service';
import { highChartConfig } from '../../../features/tvd-setup/interfaces/IhighChart.interface';
import * as Highcharts from 'highcharts';
import { TranslateService } from '@ngx-translate/core';
import { UserPreferenceService } from '../../../features/tvd-setup/services/user-preference.service';

@Component({
  selector: 'app-total-value',
  templateUrl: './total-value.component.html',
  styleUrl: './total-value.component.scss',
})
export class TotalValueComponent {

  @Input() totalValue: number;//Receiving TVD+TVO 
  @Input() totalValueDeliveredInMillion: number;
  @Input() totalOpportunityInMillion: number;
  @Input() currencyCode: any;
  bestInClassPotential: string = '';
  BIC_PotentialInMillion: number;
  transformationType = TRANSFORMATION_TYPE;
  programDetailsDeepLink = PROGRAM_TABLE.TEMPLATE_REFERENCE;
  bicDetailsDeepLink = BIC_TABLE.TEMPLATE_REFERENCE;
  bicInfo: IBICInfo;
  bicSummary: number;
  currencySymbol: string;
  isLoading: boolean = false;
  isBicEnabled:boolean=false;
  constructor(private scrollService: ScrollService,
    private customerService: CustomerService,
    public translate: TranslateService,
    private userPreferenceService: UserPreferenceService,
  ) { }

  /**Since L1 Response is coming later than BIC response 99% of times*/
  ngOnChanges(changes: SimpleChanges) {
    
    if (changes['totalValue'] && changes['totalValue'].previousValue !== changes['totalValue'].currentValue) {
      this.totalValue=0;
      this.totalValue = changes['totalValue'].currentValue;
      this.configureBIC_HighChart();
    }

  }

  ngOnInit() {
    if (this.currencyCode) {
      this.currencySymbol = this.userPreferenceService.getCurrencySymbol(this.currencyCode);
    } else {
      this.currencySymbol = ''; // Set a default currency symbol or handle accordingly
    }
    this.translate.stream('TVD').subscribe((values) => {
      this.bestInClassPotential = values.BIC;
    });
    this.customerService.bicInfo$?.subscribe(
      (result: IBICInfo) => {
        if (result) {
          this.BIC_PotentialInMillion = result?.bicPotentialSum || 0;
          this.isBicEnabled=result.isBicFlag;
          if (this.isBicEnabled)
          {
            this.configureBIC_HighChart();
          }
        }
      }
    );
  }

  /**Common HighChart configuration function to be used by TVD,TVO & BIC */
  configureBIC_HighChart() {
    const highChartBICConfig: highChartConfig = {
      id: LANDINGPAGE.BIC,
      border: LANDINGPAGE.BICBORDER,
      catogory: this.bestInClassPotential,
      maxLimit: this.totalValue
    }
    if (this.isBicEnabled) {
      this.highCharts(this.BIC_PotentialInMillion, highChartBICConfig);
    }
    this.isLoading = false;
  }

  highCharts(value: number, _highChartConfig: highChartConfig): void {
    // @ts-ignore
    Highcharts.chart(_highChartConfig.id, {
      chart: {
        type: 'bar',
        margin: [0, 0, 0, 0],
        height: 40,
        width: 700,
        backgroundColor: 'transparent',
      },
      plotOptions: {
        bar: {
          borderRadius: '50%',
          minPointLength: 5,
        },
        series: {
          groupPadding: 0.1,
          pointPadding: 0.4,
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      title: {
        text: '',
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
        min: 5,
        max: _highChartConfig.maxLimit,
      },
      tooltip: {
        enabled: true,
        headerFormat: '<table>',
        pointFormat:
          `<tr><td style="padding:0;font-size:1.5em;font-family: Roboto">
          {series.userOptions.category}: </td>
          <td style="padding:0; font-size: 1.5em;font-family: Roboto"><b>${this.currencySymbol}&nbsp;{point.y:,.2f}</b></td></tr>`,
      },
      credits: {
        enabled: false,
      },
      accessibility: {
        enabled: false,
      },
      series: [
        {
          borderColor: _highChartConfig.border,
          showInLegend: false,
          category: _highChartConfig.catogory,
          data: [
            { y: value, color: _highChartConfig.border },
          ],
        },
      ],
    });
  }
  onViewDetails(section: string): void {
    this.scrollService.scrollToSection(section);
  }
}
