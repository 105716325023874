import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  ICVOCRequest,
  ICustomerHierarchy,
  IHierarchy,
  ISavedCVOCHierarchy,
} from '../../interfaces/ICVOCHierarchy';
import { Observable, map, startWith } from 'rxjs';
import { savedHierarchy } from '../../constant/tvd-constant';
import { ICustomerInfo } from '../../interfaces/customer.interface';
import { FilterService } from '../../services/filter.service';
import { CustomerNameErrorStateMatcher } from '../../classes/customerNameTextMatcher';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ScrollService } from './../../services/scroll.service';
import {
  DOWN_ARROW_ICON,
  ERROR_ICON,
} from '../../../../shared/constants/app.constants';

@Component({
  selector: 'app-cvoc-filter',
  templateUrl: './cvoc-filter.component.html',
  styleUrl: './cvoc-filter.component.scss',
})
export class CvocFilterComponent {
  @Output() enableApply: EventEmitter<any> = new EventEmitter();
  @ViewChildren(MatAutocompleteTrigger)
  autoComplete!: QueryList<MatAutocompleteTrigger>;
  customerInfo: ICustomerInfo;
  cvoc_l1_control = new FormControl('');
  cvoc_l2_control = new FormControl('');
  cvoc_l3_control = new FormControl('');
  cvoc_l4_control = new FormControl('');
  cvoc_l5_control = new FormControl('');

  displayAllFilter: boolean = false;
  selectedFilterCount: number = 0;

  cvocInputControls: FormControl[] = [
    this.cvoc_l1_control,
    this.cvoc_l2_control,
    this.cvoc_l3_control,
    this.cvoc_l4_control,
    this.cvoc_l5_control,
  ];
  matcher = new CustomerNameErrorStateMatcher();
  filteredOptions_cvoc: Observable<IHierarchy[]>;
  cvocLevelResponse: ICustomerHierarchy;
  selectedCvocHierarchy: ISavedCVOCHierarchy[] = [...savedHierarchy];
  disableApply: boolean = true;
  cvocfilterIterations: number[] = new Array(1);
  errorIcon: string = ERROR_ICON;
  downArrowIcon: string = DOWN_ARROW_ICON;

  constructor(
    private _filterService: FilterService,
    private cdref: ChangeDetectorRef,
    private scrollService: ScrollService,
  ) {}

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit() {
    window.addEventListener('scroll', this.onScroll.bind(this), true);
    this._filterService.currentToggle.next('CVOC');
    this._filterService.selectedEVOC.next(null); //saved evoc is now null
    this._filterService.addedSites$.next(null); //saved sites are null
    this._filterService.customerInfo.subscribe(
      (res) => (this.customerInfo = res),
    );
    const saved_cvoc = this._filterService.appliedCVOC.value;
    let isData: boolean = false;
    if (saved_cvoc?.length > 0) {
      this.fetchSavedCVOCValues(saved_cvoc);
      isData = true;
    }
    this.enableApply.emit({ apply: false, type: 'cvoc', data: isData });
  }

  onScroll(): void {
    this.scrollService.scrollEvent(null, this.autoComplete);
  }

  //This method helps in fetching the CVOC values saved in Service variable
  fetchSavedCVOCValues(cvoc_data: ISavedCVOCHierarchy[]) {
    if (!cvoc_data) return;

    this.selectedCvocHierarchy = [...cvoc_data];
    if (cvoc_data?.length == 0) {
      this.modifyCVOCFilterCount(cvoc_data.length + 1);
      return;
    }
    this.modifyCVOCFilterCount(cvoc_data.length);

    cvoc_data.forEach((data, i) => {
      this.cvocInputControls[i].setValue(data);
    });
  }

  fetchNextCVOCFilter(
    targetLevel: number = 0,
    selectedFilter?: ISavedCVOCHierarchy[],
  ) {
    const savedCVOCData = {
      customerName: this.customerInfo.customerName,
      hierarchyLevel1Id: selectedFilter?.[0]?.hierarchyID,
      hierarchyLevel2Id: selectedFilter?.[1]?.hierarchyID,
      hierarchyLevel3Id: selectedFilter?.[2]?.hierarchyID,
      hierarchyLevel4Id: selectedFilter?.[3]?.hierarchyID,
    };

    const cvocRequestObj: ICVOCRequest = Object.assign(
      {},
      ...Object.keys(savedCVOCData)
        .slice(0, targetLevel + 1)
        .map((key) => ({ [key]: savedCVOCData[key] })),
    );
    this._filterService
      .getNextCVOCFilter(cvocRequestObj, this.customerInfo.customerID)
      .subscribe((res) => {
        this.cvocLevelResponse = res.response;
        this.filteredOptions_cvoc = this.cvocInputControls[
          targetLevel
        ].valueChanges.pipe(
          startWith(''),
          map((value) => this._cvocFilter(value || '')),
        );
      });
  }

  //Below method filters the options based on change in Input control
  _cvocFilter(value: any): IHierarchy[] | null {
    if (typeof value !== 'string')
      //to avoid the filteration logic as the option is directly selected
      return null;
    const filterValue = value?.toLowerCase();
    var res = this.cvocLevelResponse.hierarchy.filter((option) =>
      option.hierarchyName?.toLowerCase().includes(filterValue),
    );
    return res;
  }

  openAllFilterView() {
    this.displayAllFilter = !this.displayAllFilter;
  }

  //Below method helps in validating the Input based on Dropdown options!
  validateCvocFilter(level: number) {
    const inputControl = this.cvocInputControls[level - 1];
    const validFilter = this.cvocLevelResponse?.hierarchy?.find((y) =>
      y?.hierarchyName
        ?.toLowerCase()
        .includes(inputControl.value?.toLowerCase()),
    );
    let isData: boolean;
    if (!inputControl.value && level === 1) {
      this.disableApply = false;
      this.modifyCVOCFilterCount(level);
      this.selectedCvocHierarchy = [];
      this.persistCVOCValues();
      isData = false;
    } else if (!inputControl.value && level != 1) {
      this.disableApply = false;
      this.modifyCVOCFilterCount(level - 1);
      inputControl.setErrors(null);
      this.persistCVOCValues();
      isData = true;
    } else if (
      this.cvocLevelResponse?.hierarchy?.find(
        (y) =>
          y?.hierarchyName?.toLowerCase() == inputControl.value?.toLowerCase(),
      )
    ) {
      this.disableApply = false;
      inputControl.setErrors(null);
      isData = true;
    } else if (validFilter) {
      this.disableApply = true;
    } else {
      this.disableApply = true;
      inputControl.setErrors({ invalid: true });
      this.modifyCVOCFilterCount(level);
      isData = true;
    }

    this.enableApply.emit({
      apply: this.disableApply,
      type: 'cvoc',
      data: isData,
    });
  }

  /**
   * Retrieves and processes the CVOC options for the specified level.
   *
   * This method performs the following actions:
   * 1. Fetches the next CVOC filter based on the provided level and the selected CVOC hierarchy.
   * 2. Modifies the CVOC filter count for the given level.
   * 3. Resets the filtered CVOC options to null.
   * 4. Persists the current CVOC values.
   *
   * @param {number} level - The level for which to retrieve and process the CVOC options.
   */
  getCurrentLevelCVOCOptions(level: number) {
    this.fetchNextCVOCFilter(level - 1, this.selectedCvocHierarchy);
    this.modifyCVOCFilterCount(level);
    this.filteredOptions_cvoc = null;
    this.persistCVOCValues();
  }

  /**
   * Modifies the CVOC filter count and updates the relevant controls and hierarchy.
   *
   * @param {number} level - The level to which the CVOC filter should be modified.
   *                         If the level is greater than 5, the function returns early.
   *
   * @remarks
   * This method performs the following actions:
   * - Initializes `cvocfilterIterations` with a new array of the specified level.
   * - Resets the value of each control in `cvocInputControls` starting from the specified level.
   * - Fills the `selectedCvocHierarchy` array with `null` values from the specified level up to index 5.
   */
  modifyCVOCFilterCount(level: number) {
    if (level > 5) return;
    this.cvocfilterIterations = new Array(level);
    this.cvocInputControls.slice(level).forEach((control) => {
      control.setValue(null);
    });
    this.selectedCvocHierarchy.fill(null, level, 5);
  }

  displayFnCVOC(option: any) {
    return option?.hierarchyName;
  }

  ngOnDestroy() {
    this.persistCVOCValues();
  }

  /**
   * Persists the selected CVOC hierarchy values by filtering out any entries with a null hierarchyID
   * and then updates the selected CVOC values in the filter service.
   */
  persistCVOCValues() {
    this.selectedCvocHierarchy = this.selectedCvocHierarchy.filter(
      (x) => x?.hierarchyID != null,
    );
    this._filterService.selectedCVOC.next(this.selectedCvocHierarchy);
  }

  /**
   * Handles the event when an option is selected in the CVOC filter.
   *
   * @param level - The level of the CVOC hierarchy being selected.
   * @param selection - Optional boolean indicating if the selection is made.
   *
   * This method performs the following actions:
   * - Retrieves the hierarchy ID from the input control value based on the level.
   * - Finds the corresponding filter in the CVOC level response hierarchy.
   * - If a valid filter is found and the level is less than or equal to 5 and selection is true:
   *   - Updates the selected CVOC hierarchy with the current filter details.
   *   - Modifies the CVOC filter count for the next level.
   *   - Enables the apply button and emits an event to notify the change.
   * - If no valid filter is found or selection is false:
   *   - Modifies the CVOC filter count for the current level.
   * - Clears the filtered options for CVOC.
   * - Persists the current CVOC values.
   */
  onHierarchyOptionSelected(level: number, isOptionSelected?: boolean) {
    const inputControlValue =
      this.cvocInputControls[level - 1].value?.hierarchyId;
    const currentSelectedFilter = this.cvocLevelResponse?.hierarchy?.find(
      (y) => y.hierarchyId === inputControlValue,
    );
    if (currentSelectedFilter && level <= 5 && isOptionSelected) {
      this.selectedCvocHierarchy[level - 1] = {
        level: level,
        hierarchyID: currentSelectedFilter.hierarchyId,
        hierarchyName: currentSelectedFilter.hierarchyName,
      };
      this.modifyCVOCFilterCount(level + 1);
      this.disableApply = false;
      this.enableApply.emit({
        apply: this.disableApply,
        type: 'cvoc',
        data: true,
      });
    } else {
      this.modifyCVOCFilterCount(level);
    }
    this.filteredOptions_cvoc = null;
    this.persistCVOCValues();
  }
}
