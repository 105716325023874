import { NgxUiLoaderConfig, PB_DIRECTION, SPINNER } from 'ngx-ui-loader';
import {
  ColumnDataType,
  ITableConfiguration,
  ITableMatcher,
} from '../../features/tvd-setup/interfaces/ITable';
// ADD ALL APPLICATION CONSTANTS HERE
export const TVD = 'TVD';
export const VIEW_MORE = 'View More';
export const VIEW_LESS = 'View Less';
export const WATER = 'Water';
export const ENERGY = 'Energy';
export const WASTE = 'Waste';
export const COST_AND_BENEFITS = 'Cost & Benefit';
export const FOOD_SAFETY = 'Food Safety';
export const HUMAN_SAFETY = 'Human Safety';
export const WATER_SAFETY = 'Water Safety';
export const DELIGHTED_GUESTS = 'Delighted Guests';
export const BRAND = 'Brand';
export const QUALITY = 'Quality';
export const REGULATORY_COMPLIANCE = 'Regulatory Compliance';
export const INDUSTRY_COMPLIANCE = 'Industry Compliance';
export const ASSET_PROTECTION = 'Asset Protection';
export const RELIABILITY = 'Reliability';
export const CAPACITY = 'Capacity';
export const LABOR_PRODUCTIVITY = 'Labor Productivity';
export const LOCATION_PRODUCTIVITY = 'Location Productivity';
export const PRODUCT_OPTIMIZATION = 'Product Optimization';
export const RAW_MATERIAL_OPTIMIZATION = 'Raw Material Optimization';
export const VIEW_LESS_ICON_BLUE = 'arrow_drop_down.png';
export const VIEW_LESS_ICON_WHITE = 'arrow-up-white.png';
export const VIEW_MORE_ICON_BLUE = 'drop-down-blue.png';
export const VIEW_MORE_ICON_WHITE = 'arrow-down-white.png';
export const VALUE_ACTIVITY_METRICS = 'Value Activity Metrics';
export const MODIFY = 'Modify';
export const VALUE_ACTIVITY_METRIC = 'Value Activity Metric';
export const ADD_VALUE_ACTIVITY_METRIC = 'Add Value Activity Metric';
export const ADD_DATA = 'Add Data';

export const SITEFILTER_SEARCH_ICON = 'assets/images/FiltersearchIcon.svg';
export const SITE_NOT_FOUND_MSG = 'No Results found for Site';
export const PROGRAM_NOT_FOUND_MSG = 'No Results found for Program';
export const CUSTOMER_NOT_FOUND = 'No Customer Found';
export const CATEOGRYID_MANUAL = 15;
export const DIALOG_MODE_ADD: string = 'add';
export const DIALOG_MODE_MODIFY: string = 'modify';
export const APPLYVALUECHANEGE: string = 'applyClickChanges';
export const KAY_QSR: string = 'KAY-QSR';
export const SITE_IDENTIFIER = 'site';
export const SITE_SEARCH = 'Search Sites';
export const QSR: string = 'QSR';
export const HOW_WAS_THIS_CALCULATED_BLUE =
  'assets/images/question-icon-blue.svg';
export const HOW_WAS_THIS_CALCULATED_WHITE =
  'assets/images/question-icon-white.svg';
export const HOW_WAS_THIS_CAL = 'How was this calculated?';
export const DEFINITION = 'Definition';
export const ICON_CLOSE_BLACK = 'assets/images/Icon-close_black.svg';
export const ICON_CLOSE_WHITE = 'assets/images/Icon-close_white.svg';
export const unauthorized_icon =
  'assets/images/unauthorized-401-background.svg';
export const USD = 'USD';
export const UOM = 'Imperial';
export const Loading = 'Loading...';
export const UI_CONTROL_DEFAULT_VALUE_ALL = 'All';
export const ERROR_ICON = '../../../assets/images/error-icon.svg';
export const DOWN_ARROW_ICON = '../../../assets/images/Vector.svg';
export const OPERATING_TYPE_LITERALS = {
  TITLE: 'Select an operating type',
  LABEL: 'Search All Operating Types...',
  FILTER_BY: 'Filter By',
  NO_RESULT: 'No Results Found',
};
export const NOT_FOUND = 'Not found';
export const TABLE_SEARCH = {
  ICON: '../../../../assets/images/search.svg',
  LABEL: 'Search by',
};
export const PROGRAM_TABLE = {
  TITLE: 'Ecolab Programs',
  ICON: '../../../../assets/images/programIcon.svg',
  TABLE_TYPE_CUSTOMER_PROGRAMS: 'CUSTOMER PROGRAMS',
  TEMPLATE_REFERENCE: 'programDetails',
};
export const PROGRAM_MAPPER: ITableMatcher[] = [
  {
    fieldName: 'programName',
    displayColumn: 'Program Name',
    dataTypeColumn: ColumnDataType.Text,
  },
  {
    fieldName: 'currentStatus',
    displayColumn: 'Stage',
    dataTypeColumn: ColumnDataType.Text,
  },
  {
    fieldName: 'valueCategories',
    displayColumn: 'Value Categories',
    dataTypeColumn: ColumnDataType.Text,
  },
  {
    fieldName: 'locationCount',
    displayColumn: 'Locations',
    dataTypeColumn: ColumnDataType.Number,
  },
  {
    fieldName: 'locationName',
    displayColumn: 'Location Name',
    dataTypeColumn: ColumnDataType.Text,
  },
  {
    fieldName: 'totalValueDelivered',
    displayColumn: 'Delivered Value',
    dataTypeColumn: ColumnDataType.Currency,
  },
  {
    fieldName: 'pipelineValue',
    displayColumn: 'Pipeline Value',
    dataTypeColumn: ColumnDataType.Currency,
  },
];
export const BIC_TABLE = {
  TITLE: 'Best-In-Class Potential',
  ICON: '../../../../../../assets/images/bic_location_info.svg',
  TABLE_TYPE: 'CUSTOMER BIC',
  LABEL: 'Search by Value Category',
  DEFAULT_PAGESIZE: 10,
  TEMPLATE_REFERENCE: 'bicDetails',
};
export const BIC_MAPPER: ITableMatcher[] = [
  {
    fieldName: 'offeringName',
    displayColumn: 'Offering',
    dataTypeColumn: ColumnDataType.Text,
  },
  {
    fieldName: 'bicPotentialValue',
    displayColumn: 'Value Potential',
    dataTypeColumn: ColumnDataType.Currency,
  },
  {
    fieldName: 'valueCategories',
    displayColumn: 'Value Categories',
    dataTypeColumn: ColumnDataType.Text,
  },
  {
    fieldName: 'salesPotential',
    displayColumn: 'Ecolab Investment',
    dataTypeColumn: ColumnDataType.Currency,
  },
  {
    fieldName: 'potentialLocations',
    displayColumn: 'Potential Locations',
    dataTypeColumn: ColumnDataType.Number,
  },
  {
    fieldName: 'viewMoreColumn',
    displayColumn: '',
    dataTypeColumn: ColumnDataType.Link,
  },
];

export const GENERIC_TABLE_MAPPER: ITableMatcher[] = [
  {
    fieldName: 'column1',
    displayColumn: 'column 1',
    dataTypeColumn: ColumnDataType.Text,
  },
  {
    fieldName: 'column2',
    displayColumn: 'column 2',
    dataTypeColumn: ColumnDataType.Text,
  },
  {
    fieldName: 'column3',
    displayColumn: 'column 3',
    dataTypeColumn: ColumnDataType.Text,
  },
  {
    fieldName: 'column4',
    displayColumn: 'column 4',
    dataTypeColumn: ColumnDataType.Number,
  },
  {
    fieldName: 'column5',
    displayColumn: 'column 5',
    dataTypeColumn: ColumnDataType.Text,
  },
];

export const BIC_LOCATION_MAPPER: ITableMatcher[] = [
  {
    fieldName: 'siteName',
    displayColumn: 'Potential Locations',
    dataTypeColumn: ColumnDataType.Text,
  },
  {
    fieldName: 'address',
    displayColumn: 'Address',
    dataTypeColumn: ColumnDataType.Text,
  },
  {
    fieldName: 'valuePotential',
    displayColumn: 'Value Potential',
    dataTypeColumn: ColumnDataType.Currency,
  },
  {
    fieldName: 'salesPotential',
    displayColumn: 'Ecolab Investment',
    dataTypeColumn: ColumnDataType.Currency,
  },
  {
    fieldName: 'valueCategory',
    displayColumn: 'Value Categories',
    dataTypeColumn: ColumnDataType.Text,
  },
];
export const BIC_SITE = {
  BACKTITLE: 'Back to Dashboard',
  BIC_BRANDING: 'Best In Class Offering',
  OFFERING_TITLE: 'Offering Name',
  BACK_ICON: '../../../../../assets/images/backnav.svg',
};
export const BIC_MODAL = {
  BIC_HEADING_1: 'Best-in-Class Offering',
  BIC_HEADING_2: 'Applicable Sites',
  CLOSE_ICON: '../../../../../assets/images/bic_close.svg',
};
export const generic_tableConfiguration: ITableConfiguration = {
  columnList: ['coulmn1', 'coulmn2', 'coulmn3', 'coulmn4', 'coulmn5'],
  mapper: GENERIC_TABLE_MAPPER,
  tableTitle: 'Generic Table Title Placeholder',
  tableType: 'GENERIC TABLE TYPE',
  pageSizeOptions: [5, 10, 20, 50],
  pageSize: 5,
};

export const LANDINGPAGE = {
  ENTERPRISESITEPIPE: 'enterpriseSitePipleine',
  TVD: 'tvd',
  TVDBORDER: '#006BD3',
  ENTERPRISESITEPIPEBORDER: '#4fc2b0',
  BIC: 'totalBic',
  BICBORDER: '#7AB8F5',
  DATEFORMAT: 'YYYY-MM-DD',
};

export const TRANSFORMATION_TYPE = {
  withCurrency: 'currency',
  withoutCurrency: 'number',
};

export const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  // fgsColor: '#006bd3',
  fgsSize: 90,
  bgsType: SPINNER.ballSpin, // background spinner type
  fgsType: SPINNER.ballSpinClockwise, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 8, // progress bar thickness
};

export const OPTIONAL_FILTERS_LITERALS = {
  HIDDEN: 'hidden',
  FULL_SCREEN: '500rem',
  STANDARD: 'fit-content',
  GREY: 'rgba(0, 0, 0, 0.44)',
  WHITE: 'white',
};

export const TILE_IMAGE_MAPPING = [
  { tileName: WATER, imagePath: '../../../../../assets/images/drop.svg' },
  { tileName: ENERGY, imagePath: '../../../../../assets/images/fire.svg' },
  { tileName: WASTE, imagePath: '../../../../../assets/images/recycle.svg' },
  {
    tileName: COST_AND_BENEFITS,
    imagePath: '../../../../../assets/images/cost-&-benefits.svg',
  },
  {
    tileName: FOOD_SAFETY,
    imagePath: '../../../../../assets/images/food_safty.svg',
  },
  {
    tileName: HUMAN_SAFETY,
    imagePath: '../../../../../assets/images/human_safety.svg',
  },
  {
    tileName: WATER_SAFETY,
    imagePath: '../../../../../assets/images/water_safety.svg',
  },
  {
    tileName: DELIGHTED_GUESTS,
    imagePath: '../../../../../assets/images/delighted_guests.svg',
  },
  { tileName: BRAND, imagePath: '../../../../../assets/images/brand.svg' },
  { tileName: QUALITY, imagePath: '../../../../../assets/images/quality.svg' },
  {
    tileName: REGULATORY_COMPLIANCE,
    imagePath: '../../../../../assets/images/regular_compliance.svg',
  },
  {
    tileName: INDUSTRY_COMPLIANCE,
    imagePath: '../../../../../assets/images/industry_compliance.svg',
  },
  {
    tileName: ASSET_PROTECTION,
    imagePath: '../../../../../assets/images/asset_protection.svg',
  },
  {
    tileName: RELIABILITY,
    imagePath: '../../../../../assets/images/reliability.svg',
  },
  {
    tileName: CAPACITY,
    imagePath: '../../../../../assets/images/capacity.svg',
  },
  {
    tileName: LABOR_PRODUCTIVITY,
    imagePath: '../../../../../assets/images/labor_productivity.svg',
  },
  {
    tileName: LOCATION_PRODUCTIVITY,
    imagePath: '../../../../../assets/images/location_productivity.svg',
  },
  {
    tileName: PRODUCT_OPTIMIZATION,
    imagePath: '../../../../../assets/images/product_optimization.svg',
  },
  {
    tileName: RAW_MATERIAL_OPTIMIZATION,
    imagePath: '../../../../../assets/images/raw_material.svg',
  },
];

export const BIC_TILE_IMAGE_MAPPING = [
  { tileName: WATER, imagePath: '../../../assets/images/drop.svg' },
  { tileName: ENERGY, imagePath: '../../../assets/images/fire.svg' },
  { tileName: WASTE, imagePath: '../../../assets/images/recycle.svg' },
  {
    tileName: COST_AND_BENEFITS,
    imagePath: '../../../assets/images/cost-&-benefits.svg',
  },
];

export const EDIT = '/assets/images/editIcon.svg';
export const MODIFYIMG = '../../../../../assets/images/modify.svg';
export const PLUSWHITE = '../../../../../assets/images/add_data.svg';
export const ADD_NULL = '../../../assets/images/add_null.svg';
export const DELETE_ICON = '../../../../../assets/images/delete_icon.svg';
export const CLOSE = '../../../../../assets/images/close.svg';
export const ECLOLAB = '../../../../../assets/images/ecolab.svg';
export const USER = '../../../../../assets/images/user.svg';
export const LANGUAGE_ENGLISH_KEY = 'en';
export const LANGUAGE_ENGLISH_VALUE = 'English';
