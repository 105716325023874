import { Injectable } from '@angular/core';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Observable, catchError, throwError } from 'rxjs';
import { IAuthorizationApi } from '../../interfaces/IAuthorizationApi';
import { environment } from '../../../environments/environment';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  activeAccount: any;
  accounts: any;
  headers = new HttpHeaders({
    'Content-type': 'application/json',
  });
  constructor(
    private readonly http: HttpClient,
    private readonly authService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService,
  ) {}

  /**
   *Method used to get user Application access from the data source.
   * @returns Api response as Observable
   */
  getCurrentUserAppAccess(): Observable<IAuthorizationApi> {
    let authorizationResult = this.http
      .get<IAuthorizationApi>(environment.CDM_AUTHORIZATION_URL)
      .pipe(catchError(this.errorHandler));
    return authorizationResult;
  }

  errorHandler(error: HttpErrorResponse) {
    console.error(error);
    return throwError(() => new Error(error.message || 'Server Error'));
  }

  /**
   * Method used to update the user login status.
   * @param emailId Current user email Id
   * @param loginStatus status of login
   * @returns Observable User login status as response
   */
  updateUserLoginStatus(
    emailId: string,
    loginStatus: boolean,
  ): Observable<any> {
    let loginStatusPayload = {
      emailId: emailId,
      isLoggedOut: loginStatus,
    };
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const url = `${environment.API_DOTNET_TVD_Logout_URL}`;
    const body = JSON.stringify(loginStatusPayload);
    return this.http.post(url, body, { headers: headers });
  }

  logoutTVDAPP(loggedOutStatus: any) {
    this.updateUserLoginStatus(
      this.authService.instance.getAllAccounts()[0].localAccountId,
      loggedOutStatus,
    ).subscribe(() => {
      this.authService.logoutRedirect({
        postLogoutRedirectUri: environment.ECOLAB_WEB,
      });
      this.authService.logout();
    });
  }

  checkLoggedOutService() {
    const url = `${environment.API_DOTNET_TVD_Logout_URL}`;
    return this.http.get<any>(url, {
      params: {
        EmailId: this.authService.instance.getAllAccounts()[0].localAccountId,
      },
    });
  }

  getAllAccountsLength(): any {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  getLoggedInUserName(): any {
    return this.authService.instance.getAllAccounts()[0].name;
  }

  checkAndSetActiveAccount(): void {
    this.activeAccount = this.authService.instance.getActiveAccount();
    if (
      !this.activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      this.accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(this.accounts[0]);
    }
  }

  getUserMailID() {
    return this.authService.instance.getAllAccounts()[0].localAccountId;
  }
}
