import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FilterService } from '../../services/filter.service';
import { ICustomerInfo } from '../../interfaces/customer.interface';
import { ALL_PREFIX, FILTER_TYPES } from '../../constant/tvd-constant';
import { FormControl } from '@angular/forms';
import { Observable, from, map, startWith } from 'rxjs';
import {
  ERROR_ICON,
  OPERATING_TYPE_LITERALS,
} from '../../../../shared/constants/app.constants';
import {
  IOperatingTypePayload,
  ISavedOperatingType,
  ISubOperatingType,
  SubOperatingType,
} from '../../interfaces/IOperatingType.interface';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { CustomerNameErrorStateMatcher } from '../../classes/customerNameTextMatcher';
import { ScrollService } from './../../services/scroll.service';

@Component({
  selector: 'app-operating-type-filter',
  templateUrl: './operating-type-filter.component.html',
  styleUrl: './operating-type-filter.component.scss',
})
export class OperatingTypeFilterComponent {
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
  autoComplete!: MatAutocompleteTrigger;
  @Output() enableApply: EventEmitter<any> = new EventEmitter();
  opTypeInput = new FormControl();
  customerInfo: ICustomerInfo;
  opTypeLiterals = OPERATING_TYPE_LITERALS;
  filterName = FILTER_TYPES.OPTYPE;
  selectedOperatingType: string;
  operatingTypeList: string[] = [];
  subOperatingTypeList: ISubOperatingType;
  subOperatingTypeListDynamic: SubOperatingType[] = [];
  filteredOptions: Observable<SubOperatingType[]>;
  isBusy: boolean = true;
  matcher = new CustomerNameErrorStateMatcher();
  errorIcon: string = ERROR_ICON;

  constructor(
    private _filterService: FilterService,
    private scrollService: ScrollService,
  ) {}

  ngOnInit() {
    window.addEventListener('scroll', this.onScroll.bind(this), true);
    this.customerInfo = this._filterService.customerInfo?.value;
    if (this.customerInfo) {
      const selectedOpType = this._filterService.appliedOperatingType$?.value;
      if (selectedOpType) {
        this.selectedOperatingType = selectedOpType.subOpType;
        this.enableApply.emit({ apply: false, type: 'opType', data: true });
        this.opTypeInput.setValue(this.selectedOperatingType);
      } else
        this.enableApply.emit({ apply: false, type: 'opType', data: false });
      this.getOperatingTypeList();
      this.filteredOptions = this.opTypeInput.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value || '')),
      );
    }
  }

  onScroll(): void {
    this.scrollService.scrollEvent(this.autoComplete);
  }

  /**
   * below function will accept the operating type input value and return list of operatingType and subTypes which contains that value
   * @param value: operating type input value
   * @returns object of filtered list, Object contains operatingType: string and subTypes: string[];
   */
  public _filter(value: string): Array<SubOperatingType> {
    const filteredList = this.subOperatingTypeListDynamic.map((sub) => ({
      operatingType: sub.operatingType,
      subTypes: sub.subTypes.filter((s) =>
        s.toLowerCase().includes(value.toLowerCase()),
      ),
    }));
    //sorting based on the all and then alphabetical oreder
    filteredList.forEach((sub) => {
      sub.subTypes.sort((a, b) => {
        const aLower = a.toLowerCase().trim();
        const bLower = b.toLowerCase().trim();

        const aStartsWithAll = aLower.startsWith('all ');
        const bStartsWithAll = bLower.startsWith('all ');

        // If 'a' starts with "ALL ", it should come first
        if (aStartsWithAll && !bStartsWithAll) {
          return -1; // 'a' comes first
        }
        // If 'b' starts with "ALL ", it should come first
        if (!aStartsWithAll && bStartsWithAll) {
          return 1; // 'b' comes first
        }

        // If neither starts with "ALL ", sort alphabetically
        return aLower.localeCompare(bLower);
      });
    });

    // Check if all subTypes arrays are empty
    const isAllSubTypesEmpty = filteredList.every(
      (sub) => sub.subTypes.length === 0,
    );

    // If all are empty, set error message
    if (isAllSubTypesEmpty) {
      this.opTypeInput.setErrors({ invalid: true });
    } else {
      this.opTypeInput.setErrors(null);
    }

    return filteredList.filter((x) => x.subTypes.length >= 1);
  }

  /**
   * below function will fetch subTypes for each Operating type
   * @returns array of operating types
   */
  getOPTypeSubOptions() {
    const opList: IOperatingTypePayload = {
      operatingType: this.operatingTypeList,
    };
    this._filterService
      .fetchSubOperatingTypeList(this.customerInfo.customerID, opList)
      .subscribe((res) => {
        this.subOperatingTypeList = res.response;
        this.subOperatingTypeList = this.addSubOperatingWithAllPrefix();
        this.subOperatingTypeListDynamic = [
          ...this.subOperatingTypeList.operatingSubType,
        ];
        //to get the latest filtered list
        this.filteredOptions = this.opTypeInput.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value || '')),
        );
      });
  }

  /**
   * below function will fetch OperatingTypeList
   * @returns array of operating types
   */
  getOperatingTypeList() {
    this._filterService
      .fetchOperatingTypeList(this.customerInfo.customerID)
      .subscribe((res) => {
        this.operatingTypeList = res.response.operatingType || [];
        this.isBusy = false;
        this.getOPTypeSubOptions();
      });
  }

  /**
   * below function will add first subType as operatingType with prefix "ALL"
   * @returns object with two fields: operatingType as a string and subTypes as string array
   */
  addSubOperatingWithAllPrefix(): ISubOperatingType {
    return {
      operatingSubType: this.subOperatingTypeList.operatingSubType.map(
        (subOpType) => ({
          operatingType: subOpType.operatingType,
          subTypes: [...subOpType.subTypes],
        }),
      ),
    };
  }

  /**
   * below function will accept the subTypes array and return the observable for the @for loop
   * @param subTypes
   * @returns observable string array
   */
  getSubTypesObservable(subTypes: string[]): Observable<string[]> {
    return from([subTypes]);
  }

  /**
   * below function will accept the operating type input value and return object which contains operatingType and subOpType
   * @param val : operating type input value
   * @returns object with operatingType and operatingSubType
   */
  extractOpValues(val: string): ISavedOperatingType | undefined {
    const result: ISavedOperatingType = this.subOperatingTypeListDynamic
      .map((item) => ({
        operatingType: item.operatingType,
        subOpType: item.subTypes.find(
          (subType) => subType.toLowerCase() === val.toLowerCase(),
        ),
      }))
      .filter((item) => item.subOpType !== undefined)[0];
    return result;
  }

  validateOPType(selection: boolean = false) {
    const val = this.opTypeInput.value;
    if (selection) {
      const result = this.extractOpValues(val);
      this._filterService.selectedOperatingType$.next(result);
    }
    if (!val) {
      this._filterService.selectedOperatingType$.next(null);
      this.enableApply.emit({ apply: false, type: 'opType', data: false });
      return;
    }

    const filteredList = this.subOperatingTypeListDynamic.map((sub) => {
      const operatingType = sub.operatingType.toLowerCase();
      return {
        operatingType,
        subTypes: sub.subTypes.filter(
          (s) =>
            s.toLowerCase() === val.toLowerCase() ||
            val.toLowerCase() === operatingType,
        ),
      };
    });

    const isAllSubTypesEmpty = filteredList.every(
      (sub) => sub.subTypes.length === 0,
    );
    if (!isAllSubTypesEmpty) {
      const result = this.extractOpValues(val);
      this._filterService.selectedOperatingType$.next(result);
      this.enableApply.emit({ apply: false, type: 'opType', data: true });
    } else {
      this._filterService.selectedOperatingType$.next(null);
      this.enableApply.emit({ apply: true, type: 'opType', data: false });
    }
  }

  clearInput(event: MouseEvent) {
    event.stopPropagation();
    this.opTypeInput.setValue('');
    this._filterService.selectedOperatingType$.next(null);
    this.enableApply.emit({ apply: false, type: 'opType', data: false });
  }
}
