<div class="customer">
  <div class="customer-header">
    <!-- <app-header [title]="'TVD.MYTOTALVALUEDELIVERED' | translate"></app-header> -->
    <lib-one-customer-ui-header
      [userName]="userName"
      [options]="options"
      (emitOption)="emitOption($event)"
    ></lib-one-customer-ui-header>
  </div>
  <!-- <ngx-ui-loader></ngx-ui-loader> -->
  @if (isLoading) {
    <div class="loading-background">
      <mat-spinner
        class="loading-spinner"
        [diameter]="123"
        [strokeWidth]="8"
      ></mat-spinner>
    </div>
  }
  <div class="customer-nav">
    <div class="home-button">
      <a href="{{ homeButtonURL }}">
        <svg
          width="28"
          height="24"
          viewBox="0 0 28 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 4.25335L20.6667 10.2534V20.6667H18V12.6667H10V20.6667H7.33335V10.2534L14 4.25335ZM14 0.666687L0.666687 12.6667H4.66669V23.3334H12.6667V15.3334H15.3334V23.3334H23.3334V12.6667H27.3334L14 0.666687Z"
            fill="white"
          />
        </svg>
      </a>
    </div>
  </div>
  <div class="customer-main">
    @if (siteFlag) {
      <app-bic-site-view
        [rowInfo]="rowInfoForSiteBIC"
        (siteFlag)="checkForSite($event)"
      ></app-bic-site-view>
    }
    <div
      [hidden]="siteFlag"
      [className]="sidenav.opened ? 'leftnav-opened' : 'leftnav-closed'"
    >
      <mat-sidenav-container>
        <mat-sidenav
          #sidenav
          mode="over"
          fixedInViewport="true"
          [style.marginTop.px]="62"
          class="sidenav-container-home"
        >
          <div class="close-icon-home">
            <button mat-button (click)="sidenav.toggle()">
              <a><img [src]="close_icon" alt="close" /> </a>
            </button>
          </div>
          <div class="home-icon" (click)="sidenav.toggle()">
            <div>
              <a (click)="homepage()">
                <img [src]="home_icon" alt="home" />
              </a>
            </div>
            <div class="home-nav" (click)="homepage()">
              {{ "TVD.HOME" | translate }}
            </div>
          </div>
        </mat-sidenav>
      </mat-sidenav-container>
      <div class="customer-main-container">
        <div class="side-nav">
          <a (click)="sidenav.toggle()">
            <img [src]="hamburger_menu" alt="hamburger-menu" />
          </a>
        </div>
        <div class="customer-filter">
          <h1>{{ title }}</h1>
          <div class="customer-filter-main">
            <div class="customer-filter-main-display">
              <div
                #customerFilter
                [ngClass]="
                  showMainFilterToggle
                    ? 'customer-filter-open'
                    : 'customer-filter-close'
                "
                (click)="openFilterView()"
              ></div>
              <div class="customer-filter-align">
                <div class="customer-filter-data">
                  {{ custDisplayName || customerNotFoundMessage }}
                </div>
                <!-- Commented for Hotfix PBI: 732810 -->
                <!-- <div class="customer-filter-sites">{{ siteListLength }} {{'TVD.SITES' | translate}}</div> -->
              </div>
            </div>
            @if (showMainFilterToggle) {
              <div class="customer-filter-wrapper">
                <div class="customer-filter-label">
                  <span class="customer-filter-label--selectCustomer">{{
                    "TVD.SELECTACUSTOMER" | translate
                  }}</span>
                </div>
                <mat-form-field
                  appearance="outline"
                  class="customer-filter-wrapper-customer-list"
                >
                  <mat-label>{{ "TVD.CUSTOMER" | translate }}</mat-label>
                  <mat-icon
                    class="customerName"
                    matPrefix
                    aria-label="search"
                    [ngClass]="{ invalid: custNameControl.invalid }"
                    >search</mat-icon
                  >
                  <input
                    type="text"
                    [placeholder]="'TVD.SEARCH_CUSTOMERS' | translate"
                    aria-label="Number"
                    matInput
                    [formControl]="custNameControl"
                    [matAutocomplete]="auto"
                    (keyup)="customerSelection()"
                    id="sample"
                    [errorStateMatcher]="matcher"
                  />
                  <mat-autocomplete
                    class="autoCompleteTest"
                    autoActiveFirstOption
                    #auto="matAutocomplete"
                    [displayWith]="displayFn"
                    (optionSelected)="customerSelection()"
                  >
                    @for (option of filteredOptions | async; track option) {
                      <mat-option
                        class="mat-option-test"
                        [value]="option.customerName"
                        >{{ option.customerName }}</mat-option
                      >
                    }
                  </mat-autocomplete>
                  @if (custNameControl.getError("required")) {
                    <mat-error>{{
                      "TVD.CUSTOMERNAMEISMANDATORY" | translate
                    }}</mat-error>
                  }
                </mat-form-field>
                <div class="customer-filter-label">
                  <span class="customer-filter-label--timeRangeLabel">{{
                    "TVD.TIMERANGE" | translate
                  }}</span>
                </div>
                <div class="customer-filter-wrapper-daterange">
                  <div>
                    <mat-form-field appearance="outline">
                      <mat-label>{{ "TVD.STARTDATE" | translate }}</mat-label>
                      <input
                        matInput
                        [matDatepicker]="sdate"
                        [max]="start_date_max"
                        [min]="start_date_min"
                        [formControl]="startDate"
                        readonly
                        id="input-style"
                      />
                      <mat-datepicker-toggle
                        matIconSuffix
                        [for]="sdate"
                      ></mat-datepicker-toggle>
                      <mat-datepicker
                        #sdate
                        startView="multi-year"
                        (monthSelected)="
                          setMonthAndYear($event, sdate, 'start')
                        "
                        panelClass="example-month-picker"
                      >
                      </mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div>
                    <mat-form-field appearance="outline">
                      <mat-label>{{ "TVD.ENDDATE" | translate }}</mat-label>
                      <input
                        matInput
                        [matDatepicker]="edate"
                        [max]="end_date_max"
                        [min]="end_date_min"
                        [formControl]="endDate"
                        id="input-style"
                        readonly
                      />
                      <mat-datepicker-toggle
                        matIconSuffix
                        [for]="edate"
                        [disabled]="disableEndDate"
                      ></mat-datepicker-toggle>
                      <mat-datepicker
                        #edate
                        startView="multi-year"
                        (monthSelected)="setMonthAndYear($event, edate, 'end')"
                      >
                      </mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="filter-action">
                  <div class="clear-filter">
                    <a (click)="clearFilter()">{{ "TVD.CLEAR" | translate }}</a>
                  </div>
                  <button
                    type="button"
                    class="apply-btn"
                    mat-raised-button
                    color="primary"
                    [disabled]="applyDisabled"
                    (click)="applyFilters()"
                  >
                    {{ "TVD.APPLY" | translate }}
                  </button>
                </div>
              </div>
            }
            <div class="all-filters-wrapper">
              <app-optional-filters
                (filterRequestPayload)="fetchOptionalFiltersResults($event)"
              ></app-optional-filters>
            </div>
          </div>
        </div>
      </div>
      <!-- <app-total-value
        [totalValue]="totalValue"
        [totalValueDeliveredInMillion]="tvdConvertedToMillion"
        [totalOpportunityInMillion]="totalOpportunityInMillion"
        [currencyCode]="currencyCode"
      ></app-total-value> -->

      <div class="libTotalValueWrapper">
        <lib-one-customer-ui-lib-total-value
          [totalValue]="totalValue"
          [totalValueDelivered]="tvdConvertedToMillion"
          [totalOpportunity]="totalOpportunityInMillion"
          [isBicEnabled]="isBicEnabled"
          [BIC_PotentialInMillion]="BIC_PotentialInMillion"
          [currencyCode]="currencyCode"
          [currencySymbol]="currencySymbol"
          [showViewDetailsLink]="true"
          (clickedViewDetails)="onViewDetails($event)"
        >
        </lib-one-customer-ui-lib-total-value>
      </div>
      <div class="customer-main-L1">
        <!-- <div class="customer-main-L1-wrapper"> -->
        <div *ngFor="let tileData of L1TilesData?.L1Tiles; let i = index">
          <lib-one-customer-ui-lib-cards
            [bgColor]="
              tileData?.tileName === 'Water' ||
              tileData?.tileName === 'Energy' ||
              tileData?.tileName === 'Waste'
                ? 'var(--secondary-dark-blue, #093675)'
                : '#fff'
            "
            class="tile-align"
          >
            <lib-one-customer-ui-lib-tile-data
              [customerTilesData]="tileData"
              [currencyCode]="currencyCode"
              [currencySymbol]="currencySymbol"
            ></lib-one-customer-ui-lib-tile-data>
          </lib-one-customer-ui-lib-cards>
          <!-- </div> -->
        </div>
      </div>
      <div class="customer-main-L2">
        <div class="customer-main-L2-heading">
          <div class="customer-main-L2-header">
            <img
              class="customer-main-L2-img"
              src="../../../../../assets/images/cost-&-benefits.svg"
            />
            <h3>{{ "TVD.REDUCEDCOSTANDBENEFITS" | translate }}</h3>
          </div>
          <div class="customer-main-L2-subhead">
            <p>{{ "TVD.REDUCED_COST_AND_BENEFITS_SUBHEAD" | translate }}</p>
          </div>
          <div></div>
        </div>
        <div class="customer-main-L2-wrapper">
          <div *ngFor="let tileData of filteredL2Data" class="tile-align">
            <lib-one-customer-ui-lib-cards
              *ngIf="tileData.totalValueDelivered !== ''"
              [bgColor]="
                tileData?.tileName === 'Water' ||
                tileData?.tileName === 'Energy' ||
                tileData?.tileName === 'Waste'
                  ? 'var(--secondary-dark-blue, #093675)'
                  : '#fff'
              "
            >
              <lib-one-customer-ui-lib-tile-data
                [customerTilesData]="tileData"
                [currencyCode]="currencyCode"
                [currencySymbol]="currencySymbol"
              ></lib-one-customer-ui-lib-tile-data>
            </lib-one-customer-ui-lib-cards>
          </div>
        </div>
      </div>
      <app-programs #programDetails></app-programs>
      @if (isBicEnabled) {
        <app-best-in-class
          #bicDetails
          (siteFlag)="checkForSite($event)"
        ></app-best-in-class>
      }
      <div
        *ngIf="
          selectedCustomer !== undefined || applyClickChanges !== undefined
        "
      >
        <lib-one-customer-ui-lib-value-activity-metrics
          [customer]="selectedCustomer"
          [applyClickChanges]="applyClickChanges"
          [currentUserEmail]="currentUserEmail"
          [valueActivityMetrics]="valueActivityMetrics"
          [typeCatogory]="vamCategories"
          (vamMetrics)="updateVamMetrics($event)"
        ></lib-one-customer-ui-lib-value-activity-metrics>

        <!-- <app-value-activity-metric
          [customer]="selectedCustomer"
          [applyClickChanges]="applyClickChanges"
        ></app-value-activity-metric> -->
      </div>
    </div>
  </div>
  <div>
    <!-- <app-footer></app-footer> -->
    <div class="footer">
      <lib-one-customer-ui-footer></lib-one-customer-ui-footer>
    </div>
  </div>
</div>
