import { Component, EventEmitter, Output } from '@angular/core';
import { FilterService } from '../../services/filter.service';
import { ICustomerInfo } from '../../interfaces/customer.interface';
import {
  ICustomerDivision,
  IDivisionInfo,
  ITransformedCustomerDivision,
} from '../../interfaces/ICustomerDivision.interface';
import { CHECKBOX_THEME, FILTER_TYPES } from '../../constant/tvd-constant';
import { KAY_QSR, QSR } from '../../../../shared/constants/app.constants';

@Component({
  selector: 'app-division-filter',
  templateUrl: './division-filter.component.html',
  styleUrl: './division-filter.component.scss',
})
export class DivisionFilterComponent {
  @Output() enableApply: EventEmitter<any> = new EventEmitter();

  divisionList: ICustomerDivision;
  customerInfo: ICustomerInfo;
  selectedCustomerDivisions: string[] = [];
  filterName = FILTER_TYPES.CUSTOMER_DIVISION;
  checkBoxColor = CHECKBOX_THEME;
  isBusy = true;
  task: ITransformedCustomerDivision[] = [];
  divisionLstPart1: ITransformedCustomerDivision[]; //This is done so that 2 parallel coluns can be segreggated in view
  divisionLstPart2: ITransformedCustomerDivision[];
  KAY_QSR = KAY_QSR;
  QSR = QSR;
  constructor(private _filterService: FilterService) {}

  ngOnInit() {
    this.customerInfo = this._filterService.customerInfo.value;
    this.getCustomerDivisions();
  }

  fetchLastSavedDivisions() {
    const custDivision = this._filterService?.appliedCustomerDivision.value;
    if (
      !custDivision ||
      custDivision.customerID !== this.customerInfo.customerID
    ) {
      this.setAll(true);
      this.isBusy = false;
      this.persistDivisionDetails();
      this.enableApply.emit({ apply: false, type: 'division', data: true }); //this will increase the filter count because all boxes are checked
      return;
    }
    this.enableApply.emit({ apply: false, type: 'division', data: true });
    this.setAll(false);
    const processDivisionList = (divisionList) => {
      divisionList.forEach((x) => {
        let obj: IDivisionInfo = { name: x.divisionName };
        custDivision.divisions.forEach((y) => {
          if (y.toLowerCase().includes(obj.name.toLowerCase())) {
            x.completed = true;
            this.selectDivisions(x, true);
          }
        });
      });
    };
    processDivisionList(this.divisionLstPart1);
    processDivisionList(this.divisionLstPart2);
    this.isBusy = false;
  }

  //Below function makes a call to API and fetched the Division List
  getCustomerDivisions() {
    this._filterService
      .fetchCustomerDivisions(this.customerInfo.customerID)
      .subscribe((res) => {
        this.divisionList = res.response;
        this.transformCustomerDivision(this.divisionList.divisions);
        this.fetchLastSavedDivisions();
      });
  }

  //below helps in splitting the entire division list length into 2 parts
  splitTheDivision(completeTransformedLst: ITransformedCustomerDivision[]) {
    const midIndex = Math.ceil(completeTransformedLst.length / 2);
    this.divisionLstPart1 = completeTransformedLst.slice(0, midIndex);
    this.divisionLstPart2 = completeTransformedLst.slice(midIndex);
  }

  //below function converts the Division API Response into a transformed response needed by View
  transformCustomerDivision(custDivisionLst: string[]) {
    custDivisionLst.forEach((x) => {
      let transformedObj: ITransformedCustomerDivision = {
        divisionName: x,
        completed: false,
        //subtasks: x.subdivisions?.map(y => ({ divisionName: y, completed: false }))
      };
      this.task.push(transformedObj);
    });
    this.splitTheDivision(this.task);
  }

  //below function is invoked when we want to perform a select operation on all divisions
  setAll(completed: boolean) {
    this.selectedCustomerDivisions = [];

    const processDivisionList = (divisionList) => {
      divisionList.forEach((x) => {
        x.completed = completed;
        this.selectDivisions(x, completed);
      });
    };

    processDivisionList(this.divisionLstPart1);
    processDivisionList(this.divisionLstPart2);
    this.checkToEnableApplyBtn();
  }

  //below method checks/unchecks the division
  selectDivisions(t: ITransformedCustomerDivision, selected: boolean) {
    const divObj: IDivisionInfo = { name: t.divisionName };
    if (selected) {
      this.selectedCustomerDivisions.push(divObj.name);
    } else {
      this.selectedCustomerDivisions = this.selectedCustomerDivisions.filter(
        (x) => x !== divObj.name,
      );
    }

    this.persistDivisionDetails();
    this.checkToEnableApplyBtn();
  }

  checkToEnableApplyBtn() {
    if (this.selectedCustomerDivisions.length > 0) {
      this.enableApply.emit({ apply: false, type: 'division', data: true });
    } else {
      this.enableApply.emit({ apply: true, type: 'division', data: false });
    }
  }

  ngOnDestroy() {
    this.persistDivisionDetails();
  }

  persistDivisionDetails() {
    var saveDivisionDetails: ICustomerDivision;
    saveDivisionDetails = {
      customerID: this.customerInfo.customerID,
      divisions: this.selectedCustomerDivisions,
    };
    this._filterService.selectedCustomerDivision.next(saveDivisionDetails);
  }
}
