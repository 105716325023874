import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ALERTICON, SNACKBAR_MESSAGE } from '../../constant/tvd-constant';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrl: './snack-bar.component.scss',
})
export class SnackBarComponent {
  constructor(private readonly _snackBar: MatSnackBar) {}
  alertImg = ALERTICON;
  msg = SNACKBAR_MESSAGE;
  public dismiss(): void {
    this._snackBar.dismiss();
  }
}
